import * as React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';
import './form.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomDatePicker from '../../helper/Form/DatePicker';
import IntPhoneNumberField from '../../helper/Form/IntPhoneNumber';
import AutocompleteSelect from '../../helper/Form/AutoSelect';
import Alerts from '../../helper/Alert';
import axios from 'axios';

const FILE_SIZE = 500 * 1024;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'First Name must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),
  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'This filed must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),
  dob: yup.string().required('Date of Birth is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phonenumber: yup.string().required('Contact is required'),
  address: yup.string().required('Address is required'),
  // .matches(/^[A-Za-z0-9\s]+$/, "Enter a valid address")
  // .max(100, "Address cannot exceed 100 characters"),
  city: yup
    .string()
    .required('City is required')
    .matches(/^[A-Za-z]+$/, 'City must contain only alphabetic characters')
    .max(30, 'City cannot exceed 30 characters'),
  state: yup.string().required('State is required'),
  district: yup.string().required('District is required'),
  zip: yup
    .string()
    .required('Pin code is required')
    .matches(/^\d{6}$/, 'Zip code must be exactly 6 digits')
    .min(6, 'Pin code must be exactly 6 digits')
    .max(6, 'Pin code must be exactly 6 digits'),

  aadharNumber: yup
    .string()
    .required('Aadhar number is required')
    .matches(/^[0-9]+$/, 'Aadhar number must contain only numbers')
    .max(12, 'Aadhar number must be exactly 12 digits')
    .min(12, 'Aadhar number must be exactly 12 digits'),

  aadharCardFront: yup
    .mixed()
    .test(
      'fileRequired',
      'Aadhar card front side image is required',
      function (value) {
        return value && !!value[0]; // Check if value is defined before accessing index 0
      }
    )
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),

  aadharCardBack: yup
    .mixed()
    .test(
      'fileRequired',
      'Aadhar card back side image is required',
      function (value) {
        return value && !!value[0]; // Check if value is defined before accessing index 0
      }
    )
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),

  passportSizePhotoFile: yup
    .mixed()
    .test('fileRequired', 'Passport size photo is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),

  marksheetsFile: yup
    .mixed()
    .test('fileRequired', 'Marksheet is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});
export default function Step1({
  formData,
  setFormData,
  handleNext,

  // fileSelected,
  // files,
}) {
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const [applicantId, setApplicantId] = React.useState('');

  React.useEffect(() => {
    const myData = localStorage.getItem('volunteersDetail');
    if (myData) {
      const storedDataString = localStorage.getItem('volunteersDetail');
      const storedData = JSON.parse(storedDataString);
      setApplicantId(storedData.volunteersDetail.id);
    }
  }, []);

  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const [files, setFiles] = React.useState([null, null, null, null]);
  const [loading, setLoading] = React.useState(false);
  console.log('NEW', files);

  const fileSelected = (index, event) => {
    const file = event.target.files[0];
    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };
  const API_URL = process.env.React_App_Api;

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  const onSubmit = async (data) => {
    console.log('data', data);
    try {
      setLoading(true);
      let dataobj = [];

      const fileData = new FormData();
      for (let i = 0; i < files.length; i++) {
        fileData.append('images', files[i]);
      }
      // const lastFourDigits = formData.phonenumber.slice(-4);
      const folderName = `${data.firstName}${
        data.lastName
      }${data.aadharNumber.slice(-4)}`;
      fileData.append('folderName', folderName);
      if (files.length > 0) {
        await axios.post(`${API_URL}/file/posts`, fileData).then((res) => {
          dataobj = res.data.files;
        });
      }
      const payload = {
        applicant: {
          firstName: data.firstName,
          lastName: data.lastName,
          dob: data.dob,
          aadharcardnumber: data.aadharNumber,
          appemail: data.email,
          phonenumber: data.phonenumber,
          aadharCardFront: dataobj[0],
          aadharCardBack: dataobj[1],
          passportSizePhotoFile: dataobj[2],
          marksheetsFile: dataobj[3],
        },
        address: {
          addressone: data.address,
          city: data.city,
          state: data.state,
          district: data.district,
          pincode: data.zip,
        },
      };
      await axios
        .patch(`${API_URL}/aid/applicantdetailsupdate/` + applicantId, payload)
        .then((res) => {
          handleNext();
          const myData = {
            step1: {
              id: res.data._id,
              firstName: res.data.applicant.firstName,
              lastName: res.data.applicant.lastName,
              phoneNumber: res.data.applicant.phonenumber,
              aadharNumber: data.aadharNumber,
              dateOfBirth: res.data.applicant.dob,
              appEmail: res.data.applicant.appemail,
              address: res.data.address.addressone,
              city: res.data.address.city,
              state: res.data.address.state,
              district: res.data.address.district,
              pincode: res.data.address.pincode,
            },
          };
          const storeStep1 = JSON.stringify(myData);
          localStorage.setItem('myUsaDatastep1', storeStep1);
        });
    } catch (error) {
      console.log('CHECK ERROR', error);
      if (error.response?.data?.statusCode === 409) {
        return setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.response?.status === 503) {
        return setAlert({
          open: true,
          message: 'Database is down. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.response) {
        return setAlert({
          open: true,
          message:
            error.response.data ||
            'An unexpected error occurred While uploading file. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (error.message) {
        return setAlert({
          open: true,
          message: error.message || 'Server Error.Please try again later',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        // Handle other errors
        setAlert({
          open: true,
          message: 'An unexpected error occurred. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });

        // Reload the project
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } finally {
      setLoading(false); // Set loading state to false when form submission completes (whether success or error)
    }
    setFormData(data);
  };

  // const [selectedFiles, setSelectedFiles] = React.useState([null, null, null]);
  // console.log("selected", selectedFiles);
  // const handleFileSelection = (index, event) => {
  //   const file = event.target.files[0];
  //   const updatedFiles = [...selectedFiles];
  //   updatedFiles[index] = file;
  //   setSelectedFiles(updatedFiles);
  // };

  //State

  const tamilNaduDistricts = [
    'Ariyalur',
    'Chengalpattu',
    'Chennai',
    'Coimbatore',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kallakurichi',
    'Kanchipuram',
    'Kanyakumari',
    'Karur',
    'Krishnagiri',
    'Madurai',
    'Mayiladuthurai',
    'Nagapattinam',
    'Namakkal',
    'Nilgiris',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Ranipet',
    'Salem',
    'Sivaganga',
    'Tenkasi',
    'Thanjavur',
    'Theni',
    'Thoothukudi',
    'Tiruchirappalli',
    'Tirunelveli',
    'Tirupathur',
    'Tiruppur',
    'Tiruvallur',
    'Tiruvannamalai',
    'Tiruvarur',
    'Vellore',
    'Viluppuram',
    'Virudhunagar',
  ];

  return (
    <>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <Box>
          <Grid
            container
            spacing={2}
            p={2}
            sx={{
              display: 'flex',
            }}
          >
            <Grid item md={2} sm={12}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        First Name <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter First Name"
                    size="small"
                    className="responsive-form"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    sx={{ width: 270, maxWidth: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Last Name <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter last Name"
                    size="small"
                    className="responsive-form"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    sx={{ width: 270, maxWidth: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2.5} sm={12} sx={{ marginTop: '-7px' }}>
              <CustomDatePicker
                label="Date of Brith"
                name="dob"
                control={control}
                disableFuture={true}
                error={errors.dob}
                className="date"
              />
              {/* <Controller
            name="dob"
            control={control}
            render={({ field }) => (
              <TextField
                id="outlined-textarea"
                {...field}
                label={
                  <label>
                    Date of Birth <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                startIcon={<CalendarMonthOutlinedIcon />}
                className="responsive-form"
                sx={{
                  width: 200,
                  maxWidth: "100%",
                }}
                error={!!errors.dob}
                helperText={errors.dob?.message}
              />
            )}
          /> */}
            </Grid>
            <Grid item md={3} sm={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Email <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter Email"
                    size="small"
                    className="responsive-form"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={{
                      width: 300,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2.5} sm={12}>
              {/* <Controller
            name="phonenumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    Phone Number <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                placeholder="Enter contact"
                size="small"
                className="responsive-form"
                error={!!errors.phonenumber}
                helperText={errors.phonenumber?.message}
                sx={{
                  width: 300,
                  maxWidth: "100%",
                }}
              />
            )}
          /> */}
              <IntPhoneNumberField
                control={control}
                name="phonenumber"
                label={
                  <>
                    Phone Number <span style={{ color: 'orangered' }}>*</span>
                  </>
                }
                // required={false}
                error={errors.phonenumber}
              />
            </Grid>
            {/* 2 Row */}
            <Grid item md={4} sm={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Address with Door No{' '}
                        <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter address"
                    size="small"
                    className="responsive-form"
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    sx={{
                      width: 400,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        City <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter city"
                    size="small"
                    className="responsive-form"
                    error={!!errors.city}
                    helperText={errors.city?.message}
                    sx={{
                      width: 400,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <FormControl
                    sx={{
                      width: 270,
                      maxWidth: '100%',
                    }}
                    size="small"
                    error={!!errors.state}
                  >
                    <InputLabel id="demo-select-small">
                      State <span style={{ color: 'orangered' }}>*</span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="State"
                      className="responsive-form"
                      defaultValue="usa"
                      disabled={true}
                    >
                      <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                    </Select>
                    <FormHelperText>{errors.state?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              {/* <Controller
            name="district"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl
                sx={{
                  width: 270,
                  maxWidth: "100%",
                }}
                size="small"
                error={!!errors.district}
              >
                <InputLabel id="demo-simple-select-error-label">
                  District <span style={{ color: "orangered" }}>*</span>
                </InputLabel>
                <Select
                  {...field}
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  label="District"
                  className="responsive-form"
                >
                  <MenuItem value="Virudhunager">Virudhunagar</MenuItem>
                  <MenuItem value="Vellore">Vellore</MenuItem>
                  <MenuItem value="Tenkasi">Tenkasi</MenuItem>
                  <MenuItem value="Tirunelveli">Tirunelveli</MenuItem>
                </Select>
                <FormHelperText>{errors.district?.message}</FormHelperText>
              </FormControl>
            )}
          /> */}

              <AutocompleteSelect
                control={control}
                name="district"
                label={
                  <>
                    District <span style={{ color: 'orangered' }}>*</span>
                  </>
                }
                size="small"
                options={tamilNaduDistricts}
                error={errors.district}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        PIN Code <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter PIN Code"
                    size="small"
                    className="responsive-form"
                    error={!!errors.zip}
                    helperText={errors.zip?.message}
                    inputProps={{ maxLength: 6 }}
                    sx={{
                      width: 300,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <Controller
                name="aadharNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Aadhar Number{' '}
                        <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter aadharNumber"
                    size="small"
                    className="responsive-form"
                    error={!!errors.aadharNumber}
                    helperText={errors.aadharNumber?.message}
                    inputProps={{ maxLength: 12 }}
                    sx={{
                      width: 300,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </Grid>
            {/* Attachments */}
            <Grid item md={12} sm={12} sx={{ marginBottom: '-15px' }}>
              <Item>
                <Typography
                  gutterBottom
                  component="div"
                  className="fw-semibold text-primary"
                >
                  Attachments
                </Typography>
              </Item>
            </Grid>

            <Grid item md={6} sm={12}>
              <Typography
                sx={{ mb: 0.5 }}
                className="fs-6 text-dark-emphasis fw-normal"
              >
                Upload Aadhar Card Front Side Image{' '}
                <span style={{ color: 'orangered' }}>*</span>
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                File size should be less than 500KB
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                Only JPG, PNG, and PDF files are allowed
              </Typography>
              <div className="input-group">
                <label
                  htmlFor="inputGroupFileAadharCardFront"
                  className="form-control border-primary"
                >
                  {files[0] ? files[0].name : 'Choose a file'}
                </label>
                <Controller
                  name="aadharCardFront"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      className="visually-hidden"
                      id="inputGroupFileAadharCardFront"
                      aria-describedby="inputGroupFileAddonAadharCardFront"
                      aria-label="Upload"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        fileSelected(0, e);
                      }}
                      required
                    />
                  )}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="inputGroupFileAddonAadharCardFront"
                  onClick={() => {
                    document
                      .getElementById('inputGroupFileAadharCardFront')
                      .click();
                  }}
                >
                  <CloudUploadIcon />
                </button>
              </div>

              {errors.aadharCardFront && (
                <Typography variant="caption" color="error">
                  {errors.aadharCardFront.message}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography
                sx={{ mb: 0.5 }}
                className="fs-6 text-dark-emphasis fw-normal"
              >
                Upload Aadhar Card Back Side Image{' '}
                <span style={{ color: 'orangered' }}>*</span>
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                File size should be less than 500KB
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                Only JPG, PNG, and PDF files are allowed
              </Typography>
              <div className="input-group">
                <label
                  htmlFor="inputGroupFileAadharCardBack"
                  className="form-control border-primary"
                >
                  {files[1] ? files[1].name : 'Choose a file'}
                </label>
                <Controller
                  name="aadharCardBack"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      className="visually-hidden"
                      id="inputGroupFileAadharCardBack"
                      aria-describedby="inputGroupFileAddonAadharCardBack"
                      aria-label="Upload"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        fileSelected(1, e);
                      }}
                      required
                    />
                  )}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="inputGroupFileAddonAadharCardBack"
                  onClick={() => {
                    document
                      .getElementById('inputGroupFileAadharCardBack')
                      .click();
                  }}
                >
                  <CloudUploadIcon />
                </button>
              </div>

              {errors.aadharCardBack && (
                <Typography variant="caption" color="error">
                  {errors.aadharCardBack.message}
                </Typography>
              )}
            </Grid>

            {/* Passport Size Photo */}
            <Grid item md={6} sm={12}>
              <Typography
                sx={{ mb: 0.5 }}
                className="fs-6 text-dark-emphasis fw-normal"
              >
                Upload Passport Size Photo{' '}
                <span style={{ color: 'orangered' }}>*</span>
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                File size should be less than 500KB
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                Only JPG, PNG, and PDF files are allowed
              </Typography>
              <div className="input-group">
                <label
                  htmlFor="inputGroupFilePassportSizePhoto"
                  className="form-control border-primary"
                >
                  {files[2] ? files[2].name : 'Choose a file'}
                </label>
                <Controller
                  name="passportSizePhotoFile"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      className="visually-hidden"
                      id="inputGroupFilePassportSizePhoto"
                      aria-describedby="inputGroupFileAddonPassportSizePhoto"
                      aria-label="Upload"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        fileSelected(2, e);
                      }}
                      required
                    />
                  )}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="inputGroupFileAddonPassportSizePhoto"
                  onClick={() => {
                    document
                      .getElementById('inputGroupFilePassportSizePhoto')
                      .click();
                  }}
                >
                  <CloudUploadIcon />
                </button>
              </div>

              {errors.passportSizePhotoFile && (
                <Typography variant="caption" color="error">
                  {errors.passportSizePhotoFile.message}
                </Typography>
              )}
            </Grid>

            {/* Marksheet */}
            <Grid item md={6} sm={12}>
              <Typography
                sx={{ mb: 0.5 }}
                className="fs-6 text-dark-emphasis fw-normal"
              >
                Upload Marksheet <span style={{ color: 'orangered' }}>*</span>
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                File size should be less than 500KB
              </Typography>
              <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
                Only JPG, PNG, and PDF files are allowed
              </Typography>
              <div className="input-group">
                <label
                  htmlFor="inputGroupFileMarkSheet"
                  className="form-control border-primary"
                >
                  {files[3] ? files[3].name : 'Choose a file'}
                </label>
                <Controller
                  name="marksheetsFile"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      className="visually-hidden"
                      id="inputGroupFileMarkSheet"
                      aria-describedby="inputGroupFileAddonMarkSheet"
                      aria-label="Upload"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        fileSelected(3, e);
                      }}
                      required
                    />
                  )}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="inputGroupFileAddonMarkSheet"
                  onClick={() => {
                    document.getElementById('inputGroupFileMarkSheet').click();
                  }}
                >
                  <CloudUploadIcon />
                </button>
              </div>
              {errors.marksheetsFile && (
                <Typography variant="caption" color="error">
                  {errors.marksheetsFile.message}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            sx={{ mt: 1, mr: 1 }}
          >
            Save & Continue
          </Button>
        </Box>
      )}
    </>
  );
}

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import 'bootstrap/dist/css/bootstrap.min.css';
import './form.css';

import { Box, Button, FormHelperText, Select, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import IntPhoneNumberField from '../../helper/Form/IntPhoneNumber';
import AutocompleteSelect from '../../helper/Form/AutoSelect';

const FILE_SIZE = 500 * 1024;

const schema = yup.object().shape({
  institutionName: yup
    .string()
    .required('Institution Name is required')
    .max(80, 'Institution Name must be at most 80 characters')
    .matches(/^[A-Za-z\s]+$/, 'Institution Name must be alphabets only'),
  institutionEmail: yup
    .string()
    .email('Invalid email')
    .required('Institution email is required'),
  institutionMobileNumber: yup
    .string()
    .required('Indian Referral Mobile Number is required'),
  institutionAddress: yup.string().required('Institution Address is required'),

  institutionCity: yup
    .string()
    .required('City is required')
    .max(30, 'City must be at most 30 characters')
    .matches(/^[A-Za-z\s]+$/, 'city must be alphabets only'),
  institutionState: yup
    .string()
    .required('State is required')
    .max(30, 'State must be at most 30 characters')
    .matches(/^[A-Za-z\s]+$/, 'state must be alphabets only'),
  institutiondistrict: yup.string().required('District is required'),
  institutionZip: yup
    .string()
    .required('Pin code is required')
    .matches(/^[0-9]{6}$/, 'Zip code must be exactly 6 digits'),

  institutionMobileNumber: yup.string().required('This filed is required'),
  beneficiaryPhone: yup.string().notRequired(), // Making the field optional,
  // .typeError("Volunteer Phone must be a number")
  // .matches(/^\d+$/, "Volunteer Phone must contain only numbers")
  // .max(10, "Volunteer Phone must be less than or equal to 10 numbers"),
  beneficiaryName: yup
    .string()
    .required('Beneficiary Name is required')
    .max(30, 'Beneficiary Name must be less than 30 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Beneficiary Name must be only alphabets'),
  bonafideCertificate: yup
    .mixed()
    .test('fileRequired', 'bonafideCertificate is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});

export default function Step1({
  formData,
  setFormData,
  handleNext,
  handleBack,
  fileSelected,
  files,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    setFormData(data);
    handleNext();
  };

  const tamilNaduDistricts = [
    'Ariyalur',
    'Chengalpattu',
    'Chennai',
    'Coimbatore',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kallakurichi',
    'Kanchipuram',
    'Kanyakumari',
    'Karur',
    'Krishnagiri',
    'Madurai',
    'Mayiladuthurai',
    'Nagapattinam',
    'Namakkal',
    'Nilgiris',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Ranipet',
    'Salem',
    'Sivaganga',
    'Tenkasi',
    'Thanjavur',
    'Theni',
    'Thoothukudi',
    'Tiruchirappalli',
    'Tirunelveli',
    'Tirupathur',
    'Tiruppur',
    'Tiruvallur',
    'Tiruvannamalai',
    'Tiruvarur',
    'Vellore',
    'Viluppuram',
    'Virudhunagar',
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: 'flex',
        }}
      >
        <Grid item md={6} sm={12}>
          <Controller
            name="institutionName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    Institution Name{' '}
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter institutionName"
                size="small"
                className="responsive-form"
                error={!!errors.institutionName}
                helperText={
                  errors.institutionName ? errors.institutionName.message : ''
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <Item>
            <Controller
              name="institutionEmail"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="outlined-textarea"
                  label={
                    <label>
                      Institution Email{' '}
                      <span style={{ color: 'orangered' }}> *</span>{' '}
                    </label>
                  }
                  // placeholder="eg:abc123@example.com"
                  size="small"
                  className="responsive-form"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  sx={{ width: 300, maxWidth: '100%' }}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3} sm={12}>
          <IntPhoneNumberField
            control={control}
            name="institutionMobileNumber"
            label={
              <label>
                Institution Mobile Number
                <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            error={errors.institutionMobileNumber}
          />
        </Grid>

        <Grid item md={6} sm={12}>
          <Controller
            name="institutionAddress"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    Institution Address{' '}
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter institutionAddress"
                size="small"
                className="responsive-form"
                error={!!errors.institutionAddress}
                helperText={
                  errors.institutionAddress
                    ? errors.institutionAddress.message
                    : ''
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <Controller
            name="institutionCity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    City <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter institutionCity"
                size="small"
                className="responsive-form"
                error={!!errors.institutionCity}
                helperText={
                  errors.institutionCity ? errors.institutionCity.message : ''
                }
                sx={{
                  width: 270,
                  maxWidth: '100%',
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <Controller
            name="institutionState"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <FormControl
                sx={{
                  width: 270,
                  maxWidth: '100%',
                }}
                size="small"
              >
                <InputLabel id="demo-select-small">
                  State
                  <span style={{ color: 'orangered' }}>*</span>
                </InputLabel>
                <Select
                  {...field} // Spread field props into the Select component
                  labelId="demo-select-small"
                  id="demo-select-small"
                  className="responsive-form"
                  disabled={true}
                >
                  <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.institutionState?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <AutocompleteSelect
            control={control}
            name="institutiondistrict"
            label={
              <>
                District <span style={{ color: 'orangered' }}>*</span>
              </>
            }
            size="small"
            options={tamilNaduDistricts}
            error={errors.institutiondistrict}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <Controller
            name="institutionZip"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      PIN Code
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter institutionZip"
                  size="small"
                  className="responsive-form"
                  error={!!errors.institutionZip}
                  helperText={
                    errors.institutionZip ? errors.institutionZip.message : ''
                  }
                  inputProps={{ maxLength: 6 }}
                  sx={{
                    width: 270,
                    maxWidth: '100%',
                  }}
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <Controller
            name="beneficiaryName"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <TextField
                {...field} // Spread field props into the TextField component
                id="outlined-textarea"
                label={
                  <label>
                    Beneficiary Name
                    <span style={{ color: 'orangered' }}> *</span>
                  </label>
                }
                placeholder="Enter Beneficiary Name"
                size="small"
                className="responsive-form"
                error={!!errors.beneficiaryName}
                helperText={
                  errors.beneficiaryName ? errors.beneficiaryName.message : ''
                }
                sx={{
                  width: 300,
                  maxWidth: '100%',
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <IntPhoneNumberField
            control={control}
            name="beneficiaryPhone"
            label=" Beneficiary Mobile Number(optional)"
            error={errors.beneficiaryPhone}
          />
        </Grid>

        {/* payment Details */}

        {/* Attachements */}
        <Grid item md={12} sm={12} sx={{ marginBottom: '-15px' }}>
          <Item>
            <Typography
              gutterBottom
              component="div"
              className="fw-semibold text-primary"
            >
              Attachments
            </Typography>
          </Item>
        </Grid>
        <Grid item md={4} sm={12}>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Upload Fee Details in College Letter Head issued by the College{' '}
              <span style={{ color: 'orangered' }}>*</span>
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              File size should be less than 500KB
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              Only JPG, PNG, and PDF files are allowed
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileBonafideCertificate"
                className="form-control border-primary"
              >
                {files[2] ? files[2].name : 'Choose a file'}
              </label>
              <Controller
                name="bonafideCertificate"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileBonafideCertificate"
                    aria-describedby="inputGroupFileAddonBonafideCertificate"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(2, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonBonafideCertificate"
                onClick={() => {
                  document
                    .getElementById('inputGroupFileBonafideCertificate')
                    .click();
                }}
              >
                <CloudUploadIcon />
              </button>
            </div>
          </>
          {errors.bonafideCertificate && (
            <Typography variant="caption" color="error">
              {errors.bonafideCertificate.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}

import React, { useState } from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import Step1 from './Stepper/step1';
import Step2 from './Stepper/step2';
import Step3 from './Stepper/step3';
import Step4 from './Stepper/step4';
import Step5 from './Stepper/step5';
import Step6 from './Stepper/step6';
import Step7 from './Stepper/step7';
import Review from './Stepper/review';

import AidRequest from './Stepper/aidRequest';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Logo from '../assets/idea-logo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Stepper/form.css';

import axios from 'axios';
import Alerts from '../helper/Alert';
import SuccessDialog from '../helper/SuccessDialog';

export default function VerticalStepper() {
  //modalBox
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  //

  const [loading, setLoading] = useState(false);
  const [applicantId, setApplicantId] = useState('');
  const [fileFolderName, setFileFolderName] = useState('');

  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    //other Details
    submittername: '',
    submitterphonenumber: '',
    // step1
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    dob: '',
    address: '',
    city: '',
    state: 'Tamil Nadu',
    district: '',
    zip: '',
    AadharNumber: '',
    aadharCardFront: '',
    aadharCardBack: '',
    passportSizePhotoFile: '',
    marksheetsFile: '',
    // step2

    fatherName: '',
    fatherOccupation: '',
    motherName: '',
    motherOccupation: '',
    familyCount: '',
    familyIncome: '',
    proofOfIncomeFile: '',
    // step3
    referralName: '',
    referralPhone: '',
    referralEmail: '',
    referralRelationship: '',
    // step4
    volunteerName: '',
    volunteerEmail: '',
    volunteerPhone: '',
    usaReferenceLetter: '',
    //aid request
    amountRequested: null,
    aidtype: '',
    reasonForRequesting: '',
    yearsofsupportisneeded: '',
    multipleyears: '',
    //step5
    institutionName: '',
    institutionMobileNumber: '',
    institutionEmail: '',
    institutionAddress: '',
    institutionCity: '',
    institutionState: 'Tamil Nadu',
    institutionZip: '',
    beneficiaryName: '',
    beneficiaryPhone: '',
    accountNumber: '',
    ifscCode: '',
    bankName: '',
    branchName: '',
    paymentAddress: '',
    bonafideCertificate: '',
    //step6
    questions1: '',
    // familyCount: "",
  });
  const [files, setFiles] = useState([null, null, null]);
  console.log('NEW', files);
  // const fileSelected = (event) => {
  //   setFiles([...files, ...event.target.files]);
  // };

  const fileSelected = (index, event) => {
    const file = event.target.files[0];
    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };
  const API_URL = process.env.React_App_Api;
  const postForm = async () => {
    // const files = [
    //   formData.aadharCardFront,
    //   formData.passportSizePhotoFile,
    //   formData.marksheetsFile,
    //   formData.proofOfIncomeFile,
    //   formData.usaReferenceLetter,
    //   formData.bonafideCertificate,
    // ];
    // console.log("FILE", files);
    // if (Object.keys(errors).length === 0) {

    try {
      setLoading(true);
      let dataobj = [];

      const fileData = new FormData();
      for (let i = 0; i < files.length; i++) {
        fileData.append('images', files[i]);
      }
      let folderName = '';
      // const lastFourDigits = formData.phonenumber.slice(-4);
      if (formData.firstName && formData.lastName && formData.phonenumber) {
        folderName = `${formData.firstName}${
          formData.lastName
        }${formData.aadharNumber.slice(-4)}`;
      } else {
        folderName = fileFolderName;
      }
      fileData.append('folderName', folderName);
      if (files.length > 0) {
        await axios
          .post(`${API_URL}/file/posts`, fileData)
          .then((res) => {
            dataobj = res.data.files;
            // dataobj.filesPath = uploadedUrls;
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  throw new Error(
                    'No files uploaded or invalid data. Please try again.'
                  );
                case 500:
                  throw new Error(
                    'Server error occurred while uploading files.'
                  );
                default:
                  throw new Error(
                    error.response.data || 'Unexpected error occurred.'
                  );
              }
            } else if (error.message) {
              throw new Error(`File upload failed: ${error.message}`);
            } else {
              throw new Error('An unknown error occurred during file upload.');
            }
          });
      }
      const data = {
        reviewcomments: 'string',
        committeenotes: '',
        financecommitteenotes: 'string',
        username: 'string',
        submittername: 'string',
        submitterphonenumber: formData.submitterphonenumber,
        comments: '',
        status: 'new',

        questions: {
          answer: 'string',
        },
        parentorguardian: {
          fathername: formData.fatherName,
          fatherprofession: formData.fatherOccupation,
          mothername: formData.motherName,
          motherprofession: formData.motherOccupation,
          familyincomepm: formData.familyIncome,
          familyCount: formData.familyCount,
          proofOfIncomeFile: dataobj[0],
        },
        aidinformation: {
          amtrequested: formData.amountRequested,
          reasonforrequesting: formData.reasonForRequesting,
          aidtype: formData.aidtype,
          multipleyears: formData.multipleyears,
        },

        institutiondetails: {
          name: formData.institutionName,
          mobileNumber: formData.institutionMobileNumber,
          email: formData.institutionEmail,
          address: formData.institutionAddress,
          city: formData.institutionCity,
          state: formData.institutionState,
          district: formData.institutiondistrict,
          pincode: formData.institutionZip,
          website: 'string',
          bonafideCertificate: dataobj[1],
        },
        paymentmethod: {
          ifsccode: formData.ifscCode,
          accountnumber: formData.accountNumber,
          bankname: formData.bankName,
          branchname: formData.branchName,
          branchAddress: formData.paymentAddress,
          beneficiaryname: formData.beneficiaryName,
          beneficiaryphone: formData.beneficiaryPhone,
        },
        // indiavolunteer: {
        //   ivname: formData.referralName,
        //   ivphone: formData.referralPhone,
        //   ivemail: formData.referralEmail,
        //   relationtoapplicant: formData.referralRelationship,
        // },
        usavolunteer: {
          // usvname: formData.volunteerName,
          // usvphone: formData.volunteerPhone,
          // email: formData.volunteerEmail,
          usaReferenceLetter: dataobj[2],
        },
        msetScholarshipLetter: dataobj[3],
      };
      if (formData.multipleyears === 'yes') {
        data.aidinformation.yearsofsupportisneeded =
          formData.yearsofsupportisneeded;
      }
      await axios
        // .post(`${API_URL}/user/healthcheck`)
        .put(`${API_URL}/aid/newupdate/${applicantId}`, data)
        .then((res) => {
          localStorage.removeItem('myUsaDatastep1');
          localStorage.removeItem('volunteersDetail');
          setOpen(true);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 409:
                throw new Error('Conflict: Duplicate data detected.');
              case 503:
                throw new Error('Service unavailable. Please try again later.');
              default:
                throw new Error(
                  error.response.data || 'Unexpected error occurred.'
                );
            }
          } else if (error.message) {
            throw new Error(`Request failed: ${error.message}`);
          } else {
            throw new Error(
              'An unknown error occurred while submitting the form.'
            );
          }
        });
    } catch (error) {
      // if (error.response?.data?.statusCode === 409) {
      //   return setAlert({
      //     open: true,
      //     message: error.response.data?.error,
      //     severity: 'error',
      //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
      //   });
      // } else if (error.response?.status === 503) {
      //   return setAlert({
      //     open: true,
      //     message: 'Database is down. Please try again later.',
      //     severity: 'error',
      //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
      //   });
      // } else if (error.response) {
      //   return setAlert({
      //     open: true,
      //     message:
      //       error.response.data ||
      //       'An unexpected error occurred While uploading file. Please try again later.',
      //     severity: 'error',
      //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
      //   });
      // } else if (error.message) {
      //   return setAlert({
      //     open: true,
      //     message: error.message || 'Server Error.Please try again later',
      //     severity: 'error',
      //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
      //   });
      // } else {
      //   // Handle other errors
      //   return setAlert({
      //     open: true,
      //     message: 'An unexpected error occurred. Please try again later.',
      //     severity: 'error',
      //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
      //   });
      // }
      setAlert({
        open: true,
        message: error.message || 'An unexpected error occurred.',
        severity: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } finally {
      setLoading(false); // Set loading state to false when form submission completes (whether success or error)
    }
  };

  // const onChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  //   console.log(formData.submittername);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // const confirmForm = () => {

  //   swal({
  //     text: "Please Enter the Details to Submit the Form",
  //     buttons: {
  //       cancel: "Close",
  //       catch: {
  //         text: "Submit",
  //         value: "catch",
  //       },
  //     },
  //     content: (
  //       <div>
  //         <Box
  //           sx={{
  //             width: "100%",
  //             maxWidth: 400,
  //             borderRadius: "10px",
  //             boxShadow: 3,
  //             p: 2,
  //             margin: "auto",
  //           }}
  //           style={{
  //             backgroundColor: "#f5f5f5",
  //             marginRight: "15px",
  //             color: "black",
  //           }}
  //         >
  //           <Stack spacing={2}>
  //             <Item>
  //               <TextField
  //                 id="outlined-basic"
  //                 variant="outlined"
  //                 placeholder="Enter  Name *"
  //                 name="submittername"
  //                 value={formData.submittername}
  //                 onChange={onChange}
  //                 size="small"
  //                 style={{
  //                   backgroundColor: "white",
  //                 }}
  //               />
  //             </Item>
  //             <Item>
  //               <TextField
  //                 id="outlined-basic"
  //                 variant="outlined"
  //                 placeholder="Enter Submitter Phone Number *"
  //                 size="small"
  //                 name="submitterphonenumber"
  //                 value={formData.submitterphonenumber}
  //                 onChange={onChange}
  //                 style={{
  //                   backgroundColor: "white",
  //                 }}
  //               />
  //             </Item>
  //           </Stack>
  //         </Box>
  //       </div>
  //     ),
  //   }).then((value) => {
  //     switch (value) {
  //       case "catch":
  //         postForm();
  //         break;
  //       default:
  //         swal("Got away safely!");
  //     }
  //   });
  // };

  console.log(formData);
  console.log(formData.submittername);
  console.log(formData.submitterphonenumber);

  const handleNext = () => {
    console.log(activeStep);
    console.log(formData);
    console.log(errors);
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const steps = [
    {
      label: ' USA Volunteer Details',
      description: (
        <Step4
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      ),
    },
    {
      label: 'India Reference Details',
      description: (
        <Step3
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'Applicant Details',
      description: (
        <Step1
          setApplicantId={setApplicantId}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          // fileSelected={fileSelected}
          // files={files}
        />
      ),
    },
    {
      label: 'Parent/Guardian Details',
      description: (
        <Step2
          setApplicantId={setApplicantId}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },

    {
      label: 'Aid Request',
      description: (
        <AidRequest
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'Instutution Details',
      description: (
        <Step5
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'School / College Bank Details',
      description: (
        <Step6
          formData={formData}
          setFormData={setFormData}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      ),
    },
    {
      label: 'Acknowledgement & Declaration',
      description: (
        <Step7
          handleNext={handleNext}
          handleBack={handleBack}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'Review',
      description: (
        <Review
          loading={loading}
          formData={formData}
          setFormData={setFormData}
          handleBack={handleBack}
        />
      ),
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  // let applicantId = '';

  React.useEffect(() => {
    const myData = localStorage.getItem('myUsaDatastep1');
    const volunteersDetail = localStorage.getItem('volunteersDetail');
    if (myData) {
      const storedDataString = localStorage.getItem('myUsaDatastep1');
      const storedData = JSON.parse(storedDataString);
      const folderName = `${storedData.step1.firstName}${
        storedData.step1.lastName
      }${storedData.step1.phoneNumber.slice(-4)}`;
      setFileFolderName(folderName);
      setApplicantId(storedData.step1.id);
      setActiveStep(3); // Assuming Step1 is completed
    } else if (volunteersDetail) {
      // const storedDataString = localStorage.getItem('volunteersDetail');
      // const storedData = JSON.parse(storedDataString);
      // setApplicantId(storedData.step1.id);
      setActiveStep(2);
    } else {
      setActiveStep(0); // Start from the first step
    }
  }, []);

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };
  return (
    <Box sx={{ p: 2, md: '' }}>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <SuccessDialog open={open} setOpen={setOpen} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '23px', fontWeight: 'bold' }}>
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            // style={{ marginRight: "40px" }}
          />{' '}
          IDEA Scholarship Application - 2024{' '}
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            style={{ marginRight: '40px' }}
          />
        </Typography>
        {/* <img
          src={Logo}
          alt="logo"
          width={"50"}
          height={"50"}
          style={{ marginRight: "40px" }}
        /> */}
      </Box>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 7 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                {/* <Button
                  disabled={index === 0}
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button> */}
                {index === 8 ? (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>{' '}
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={modelBox}
                      onClick={postForm}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Review and Submit
                    </Button>
                  </Box>
                ) : (
                  // <Button
                  //   variant="contained"
                  //   onClick={handleNext}
                  //   sx={{ mt: 1, mr: 1 }}
                  // >
                  //   {index === steps.length - 1 ? "Finish" : "Continue"}
                  // </Button>
                  ''
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}

import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Controller, useForm } from 'react-hook-form'; // Import useForm
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FILE_SIZE = 500 * 1024;
const schema = yup.object().shape({
  termsAndConditions: yup
    .boolean()
    .oneOf([true], 'Please accept the terms and conditions'),
  MSEDLetter: yup
    .mixed()
    .test(
      'fileRequired',
      'MSET scholarship form is required',
      function (value) {
        return value && !!value[0]; // Check if value is defined before accessing index 0
      }
    )
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});

export default function Step7({ handleNext, handleBack, fileSelected, files }) {
  const {
    handleSubmit,
    register,
    control, // Add register to destructure from useForm
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // Handle form submission
    console.log('Form Data:', data);
    handleNext();
  };

  return (
    <Box>
      <div className="overflow-y-scroll">
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
          Terms and Conditions
        </Typography>
        <Typography sx={{ fontSize: '15px', color: 'black' }}>
          These terms and conditions ("Terms") govern your participation in the
          scholarship application process administered by IDEA Non-profit. By
          submitting your scholarship application, you agree to abide by these
          Terms.
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          1. Documentation of Information:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          All information provided by applicants will be documented and stored
          securely by IDEA Non-Profit org. The data submitted by applicants
          belongs to IDEA Non-Profit org and will be used solely for the purpose
          of evaluating scholarship applications..
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          2. Scholarship Approval:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          The approval of scholarship applications is subject to the discretion
          of IDEA. The approved scholarship amount may vary based on the
          decisions and approval of IDEA.
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          3. Variation of Approved Amount:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          The approved scholarship amount may vary based on the decisions and
          approval of IDEA. IDEA reserves the right to adjust the scholarship
          amount based on available funds, applicant qualifications, and other
          relevant factors..
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          4. Application Review:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          All scholarship applications will be reviewed by IDEA in a fair and
          unbiased manner. Applicants will be notified of the status of their
          application in a timely manner. Applications will be reviewed based on
          the Scholarship guidelines set by IDEA Education committee.
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          5. Confidentiality:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          IDEA will maintain confidentiality regarding all information submitted
          by applicants. Applicant data will not be shared with third parties
          without prior consent, except as required by law.
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          6. Modification of Terms:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          IDEA reserves the right to modify these Terms at any time without
          prior notice. Any changes to these Terms will be effective immediately
          upon posting on the IDEA website or other official channels.
        </Typography>
        <Typography
          sx={{ fontSize: '17px', fontWeight: 'bold', mt: 2, color: '#4d5259' }}
        >
          7. Governing Law:
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666b73' }}>
          These Terms shall be governed by and construed in accordance with the
          laws of [Jurisdiction]. Any disputes arising out of or in connection
          with these Terms shall be subject to the exclusive jurisdiction of the
          courts of [Jurisdiction]. By submitting your scholarship application,
          you acknowledge that you have read, understood, and agreed to these
          Terms.
        </Typography>
      </div>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={<Checkbox {...register('termsAndConditions')} />} // Use register from react-hook-form
          label="Please Accept the Terms and Conditions"
        />
        {errors.termsAndConditions && (
          <Typography variant="body2" color="error">
            {errors.termsAndConditions.message}
          </Typography>
        )}
      </FormGroup>

      <Grid item md={4} sm={12} width="400px">
        <Typography
          gutterBottom
          component="div"
          className="fw-semibold text-primary"
        >
          Attachments
        </Typography>
        <>
          <Typography
            sx={{ mb: 0.5 }}
            className="fs-6 text-dark-emphasis fw-normal"
          >
            MSET Scholarship Letter{' '}
            <span style={{ color: 'orangered' }}>*</span>
          </Typography>
          <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
            File size should be less than 500KB
          </Typography>
          <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
            Only JPG, PNG, and PDF files are allowed
          </Typography>
          <div className="input-group">
            <label
              htmlFor="inputGroupFileReferenceLetter"
              className="form-control border-primary"
            >
              {files[3] ? files[3].name : 'Choose a file'}
            </label>
            <Controller
              name="MSEDLetter"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  className="form-control border-primary visually-hidden"
                  id="inputGroupFileReferenceLetter"
                  aria-describedby="inputGroupFileAddonReferenceLetter"
                  aria-label="Upload"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                    fileSelected(3, e);
                  }}
                  required
                />
              )}
            />
            <button
              className="btn btn-outline-primary"
              type="button"
              id="inputGroupFileAddonReferenceLetter"
              onClick={() => {
                document
                  .getElementById('inputGroupFileReferenceLetter')
                  .click();
              }}
            >
              <CloudUploadIcon />
            </button>
          </div>
          {/* Display file selection error */}
          {errors.MSEDLetter && (
            <Typography variant="caption" color="error">
              {errors.MSEDLetter.message}
            </Typography>
          )}
        </>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

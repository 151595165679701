import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import 'bootstrap/dist/css/bootstrap.min.css';
import './form.css';

import { Box, Button, Typography } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  accountNumber: yup
    .string()
    // .typeError("Account Number must be a number")
    .matches(/^\d*$/, 'Account Number must be alphabets') // Allow only numbers
    .max(25, 'Account Number cannot exceed 25 digits') // Limit to 25 digits
    .required('Account Number is required'), // Field is required

  ifscCode: yup
    .string()
    .required('IFSC Code is required')
    .matches(
      /^[A-Za-z0-9]+$/,
      'IFSC Code must contain only alphanumeric characters'
    )
    .matches(/^[^\s]+$/, 'IFSC Code cannot contain spaces'),

  bankName: yup
    .string()
    .required('Bank Name is required')
    .max(50, 'Bank Name must be at most 50 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Bank Name'),
  branchName: yup
    .string()
    .required('Branch Name is required')
    .max(30, 'Branch Name must be at most 30 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Branch Name'),
  paymentAddress: yup.string().required('Payment Address is required'),
});

export default function Step6({
  formData,
  setFormData,
  handleNext,
  handleBack,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    setFormData(data);
    handleNext();
  };

  return (
    <>
      <Typography sx={{ fontWeight: 'bold', my: 1 }}>
        Please enter the bank details of the College. NOT your personal bank
        details
      </Typography>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: 'flex',
        }}
      >
        {/* payment Details */}
        {/* <Grid item md={12} sm={12} sx={{ marginBottom: "-15px" }}>
          <Item>
            <Typography
              gutterBottom
              component="div"
              className="fw-semibold text-primary"
            >
              Payment Details
            </Typography>
          </Item>
        </Grid> */}
        <Grid item md={4} sm={12}>
          <Controller
            name="accountNumber"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <TextField
                {...field} // Spread field props into the TextField component
                id="outlined-textarea"
                label={
                  <label>
                    Account Number
                    <span style={{ color: 'orangered' }}> *</span>
                  </label>
                }
                placeholder="Enter Account Number"
                size="small"
                className="responsive-form"
                error={!!errors.accountNumber}
                helperText={
                  errors.accountNumber ? errors.accountNumber.message : ''
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="ifscCode" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      IFSC Code
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter IFSC Code"
                  size="small"
                  className="responsive-form"
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode ? errors.ifscCode.message : ''}
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="bankName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Bank Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Bank Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.bankName} // Convert error to boolean
                  helperText={errors.bankName ? errors.bankName.message : ''}
                  fullWidth // Display error message
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="branchName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Branch Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.branchName} // Convert error to boolean
                  helperText={
                    errors.branchName ? errors.branchName.message : ''
                  } // Display error message
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="paymentAddress" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Address
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Payment Address"
                  size="small"
                  className="responsive-form"
                  fullWidth
                  error={!!errors.paymentAddress} // Convert error to boolean
                  helperText={
                    errors.paymentAddress ? errors.paymentAddress.message : ''
                  } // Display error message
                />
              </Item>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}
